@import '../libs/mixins';
@import '../libs/variables';

.visa {
  padding: 200px 0 280px;
  &-header {
    max-width: 540px;
    margin: 0 auto 80px;
    text-align: center;

    &__title {
      @include text(32px, 32px, 600, $green);
      margin-bottom: 20px;

    }
  }

  &-list {
    @include flex(space-between, flex-start);
  }

  &-item {
    padding: 72px 48px;
    box-shadow: 0px 100px 80px 0px rgba(#000, .04);
    border-radius: 36px;

    @include flex(flex-start, center);
    flex-direction: column;
    gap: 80px;

    &__img {
      height: 125px;
    }

    &__info {
      max-width: 216px;
      text-align: center;
    }

    &__title {
      @include text(20px, 28px, 500, $darkGray);
      margin-bottom: 4px;
    }

    &__text {
      @include text(16px, 21px, 400, $gray);
    }
  }
}

@media screen and (max-width: 1080px) {
  .visa {
    padding: 200px 0;
    &-header {
      margin: 0 auto 60px;
    }
  
    &-item {
      padding: 54px 24px;
  
      gap: 60px;
  
      &__img {
        height: 100px;
      }
  
      &__info {
        max-width: 216px;
      }
    }
  }
}

@media screen and (max-width: 890px) {
  .visa {
    &-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 24px;
    }

    &-item {
      &:nth-child(1) {
        grid-area: 1 / 1 / 2 / 3;
      }
    
      &:nth-child(2) {
        grid-area: 1 / 3 / 2 / 5;
      }
    
      &:nth-child(3) {
        grid-area: 2 / 2 / 3 / 4;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .visa {
    padding: 120px 0;
    &-header {
      max-width: 90%;
      margin: 0 auto 50px;
      
      &__title {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 5px;
      }
    }
  
    &-list {
      @include flex(flex-start, center);
      flex-direction: column;
      gap: 50px;
    }
  }
}