@import '../libs/mixins';
@import '../libs/variables';

.download {
  padding: 200px 0 100px;
  @include flex(center, center);
  flex-direction: column;

  &-header {
    max-width: 1120px;
    @include flex(center, center);
    flex-direction: column;
    gap: 20px;

    &__title {
      @include text(58px, 66px, 700, $darkGray);
      text-align: center;

      span {
        color: $green;
      }
    }

    &__buttons {
      @include flex(center, center);
      gap: 40px;

      &-item {
        @include btn(#fff, $green, $green, $green, 15px 36px, transparent);

        &-inverse {
          @include btn($padding: 15px 85px);
        }
      }
    }
  }

  &-body {
    &__img {
      width: 32vw;
    }
  }
}

@media screen and (max-width: 1080px) {
  .download {
    padding: 100px 0 60px;
    
    &-header {
      max-width: 710px;
      margin: 0 40px;
  
      &__title {
        font-size: 40px;
        line-height: 48px;
      }

      &__buttons {
        gap: 24px;
  
        &-item {
          padding: 13.5px 37px;
  
          &-inverse {
            padding: 13.5px 78px;
          }
        }
      }
    }
  
    &-body {
      &__img {
        width: 50vw;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .download {
    &-header {
      max-width: 100%;
      margin: 0 30px;
  
      &__title {
        font-size: 24px;
        line-height: 28px;
      }

      &__buttons {
        width: 100%;
        flex-direction: column;
        gap: 18px;
        text-align: center;
        
        &-item {
          width: 100%;

          &-inverse {
            width: 100%;
          }
        }
      }
    }
  
    &-body {
      &__img {
        width: 50vw;
      }
    }
  }
}
