@import 'libs/variables';
@import 'libs/mixins';
@import 'libs/reset';

@import 'sections/all';

.container {
  margin: 0 10%;
}

.default-text {
  @include text(18px, 28px, 400, $gray, $interFont);
}

body {
  overflow-x: hidden;
}

.hide {
  display: none;
}

@media screen and (max-width: 1368px) {
  .container {
    margin: 0 6%;
  }
}

@media screen and (max-width: 1080px) {
  .container {
    margin: 0 40px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    margin: 0 30px;
  }

  .default-text {
    font-size: 16px;
  }
}