html, body, div, span,
h1, h2, h3, h4, h5, h6, p,
a, img, em, strong,
ol, ul, li,
form, input, textarea,
button, label {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

ol, ul {
  list-style: none;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

button, input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

*, *::before, *::after {
  box-sizing: inherit;
}
